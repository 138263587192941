import * as React from "react"
import { useTranslation } from "react-i18next"

import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';

import Layout from "../components/layout"
import Seo from "../components/seo"
import LayoutContainer from '../components/layout-container';
import HeaderBioMin from '../components/header-bio-min';

const useStyles = makeStyles({
  description: {
    textAlign: 'justify',
    margin: '2em 0',
    lineHeight: '1.5em'
  },
  cardTitle: {
    margin: 0
  },
  ul: {
    listStyle: 'circle'
  },
  small: {
    fontSize: '.875em',
    opacity: 0.6,
  }
});

const NotFound = () => {
  const {t} = useTranslation("about")
  const classes = useStyles();

  return (
    <Layout>
      <Seo title={t("title")}/>
      <HeaderBioMin/>
      <LayoutContainer>
        <div className={classes.description}>
          { t('bio') }
        </div>
        <div>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Card>
                <CardContent>
                  <h3 className={classes.cardTitle}>{ t('frontend') }</h3>
                  <Grid container spacing={2}>
                    <Grid item xs> <ul className={classes.ul}> <li>Angular</li> <li>GraphQL</li> <li>PrimeNg</li> <li>WebSockets</li> </ul> </Grid>
                    <Grid item xs> <ul className={classes.ul}> <li>Javascript</li> <li>Typescript</li> <li>HTML5</li> <li>CSS3</li> </ul> </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card>
                <CardContent>
                  <h3 className={classes.cardTitle}>{ t('backend') }</h3>
                  <Grid container spacing={2}>
                    <Grid item xs> <ul className={classes.ul}> <li>.NET Core</li> <li>C#</li> <li>Entity Framework</li> <li>LINQ</li> </ul> </Grid>
                    <Grid item xs> <ul className={classes.ul}> <li>WebSockets</li> <li>SignalR</li> <li>API RestFull</li> <li>Serverless</li> </ul> </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card>
                <CardContent>
                  <h3 className={classes.cardTitle}>{ t('database') }</h3>
                  <Grid container spacing={2}>
                    <Grid item xs> <ul className={classes.ul}>
                      <li>SQL Server</li>
                      <li>PostgreSQL</li>
                      <li>MySQL</li>
                      <li>Oracle SQL</li>
                    </ul> </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} xs={12}>
              <Card>
                <CardContent>
                  <h3 className={classes.cardTitle}>{ t('cloud') }</h3>
                  <Grid container spacing={2}>
                    <Grid item xs> <ul className={classes.ul}>
                      <li>Microsoft Azure <small className={classes.small}> (Functions, BlobStorage) </small></li>
                      <li>AWS <small className={classes.small}> (CodeCommit, S3, Lambda Functions) </small></li>
                      <li>Google Cloud Platform <small className={classes.small}> (Maps API, Firebase) </small></li>
                      <li>Netlify <small className={classes.small}> (Deploy, Functions) </small></li>
                    </ul> </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </LayoutContainer>
    </Layout>
  )
}

export default NotFound
