import * as React from "react"
import { useTranslation } from 'react-i18next';

import "../styles/header-bio.scss";
import { Grid } from '@material-ui/core';

const HeaderBioMin: React.FC = () => {
  const {t} = useTranslation("header")

  return (
    <section className="header-bio">
      <div className="header-bio-overlay">
        <div className="header-bio-container container">

          <Grid container justify="center" alignContent="center" alignItems="center" spacing={2} direction='column'>

            <Grid item>
              <div className="header-bio-picture">
                <img src="https://www.higorcavalcanti.com.br/pt/assets/images/Higor-Cavalcanti.jpg"
                     alt="Higor Cavalcanti"/>
              </div>
            </Grid>

            <Grid item>
              <div className="header-bio-text">
                <div className="header-bio-author text-center">
                  Higor Cavalcanti
                </div>
                <div className="header-bio-description text-center">
                  {t("bioMin")}
                </div>
              </div>
            </Grid>
          </Grid>


        </div>
      </div>
    </section>
  );
}

export default HeaderBioMin
